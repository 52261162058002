import { projectFirestore } from '../../../firebase/config';
import router from '../../../router'

const goBack = () => {
    if (router && window.history.length > 1) {
        router.back();
    } else {
        router.push({ name: 'HomeView' });
    }
}


function isValidIsraeliPhoneNumber(phoneNumber) {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    const isIsraeliMobileNumber = /^05\d{8}$/.test(numericPhoneNumber);

    return isIsraeliMobileNumber;
}

function isValidIsraeliID(id) {
    var id = String(id).trim();
    if (id.length > 9 || id.length < 5 || isNaN(id)) return false;

    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}

function validatePassport(passport) {
    const passportRegex = /^[A-Z0-9]{6,9}$/;
    return passportRegex.test(passport);
}

function validateHebrewName(name) {
    const hebrewRegex = /^[\u0590-\u05FF']+$/;
    return hebrewRegex.test(name);
}

export {
    isValidIsraeliPhoneNumber, goBack, isValidIsraeliID, validatePassport, validateHebrewName
}