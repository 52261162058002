<template>
    <div class="home-page">
        <div style="position: relative; width: 100%;">
            <img src="../../../assets/images/bgd_family.jpg" class="card-img-big desktop-only" style="width: 100%;">
            <div class="card desktop-only"
                style="position: absolute; top: 5%; left: 3%; width: 90%; display: flex; flex-direction: row-reverse; justify-content: space-between; align-items: flex-start;">
                <div>
                    <img src="../../../assets/images/rosman-market-new-cropped.png" alt="לוגו רוסמן מרקט"
                        style="width: 300px;">
                </div>
                <div style="display: flex; flex-direction: column; align-items: center; margin-top: 3vw;"
                    class="desktop-only">
                    <div class="title1" style="font-size: 50px; font-weight: 700;">החברים והחברות של</div>
                    <div class="title1" style="font-size: 50px; font-weight: 700;">רוסמן מרקט</div>
                    <div class="title2" style="font-size: 34px; font-weight: 500;">הרשמו למועדון החברים שלנו ותוכלו
                    </div>
                    <div class="title2" style="font-size: 34px; font-weight: 500;">ליהנות ממגוון מבצעים, הטבות ועדכונים
                    </div>
                    <img src="../../../assets/images/chevron.png" alt="arrows-down" style="width: 180px;">
                </div>
            </div>
        </div>



        <div class="home-page-container" v-loading="is_loading">
            <div class="card mobile-only">
                <img src="../../../assets/images/mobile_fam.png" alt="לוגו מועדון חברים רוסמן מרקט"
                    class="card-img-big">
                <img src="../../../assets/images/rosman-market-new-cropped.png" alt="לוגו רוסמן מרקט"
                    class="card-img-top" style="margin-top: -20px;">
            </div>

            <div class="title1 mobile-only">החברים והחברות של רוסמן מרקט</div>
            <div class="title2 mobile-only">הרשמו למועדון החברים שלנו ותוכלו ליהנות ממגוון</div>
            <div class="title2 mobile-only">מבצעים, הטבות ועדכונים</div>
            <br>
            <div class="container">
                <form @submit.prevent="submitForm">
                    <div class="form-row">
                        <!-- first name -->
                        <div class="form-group">
                            <span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">person</span></span> -->
                            <label for="first-name">שם פרטי (בעברית בלבד)</label>
                            <input tabindex="0" aria-required="true" type="text" id="first-name"
                                v-model="form.firstName" class="form-control"
                                :class="{ 'border-red': fieldError.includes('firstName') }"
                                @blur="checkForm && validateForm()">
                            <span v-if="fieldError.includes('firstName')" class="error-message">יש למלא שם פרטי
                                בעברית</span>
                        </div>

                        <!-- last name -->
                        <div class="form-group">
                            <span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">person</span></span> -->
                            <label for="last-name">שם משפחה (בעברית בלבד)</label>
                            <input tabindex="0" aria-required="true" type="text" id="last-name" v-model="form.lastName"
                                class="form-control" :class="{ 'border-red': fieldError.includes('lastName') }"
                                @blur="checkForm && validateForm()">
                            <span v-if="fieldError.includes('lastName')" class="error-message">יש למלא שם משפחה
                                בעברית</span>
                        </div>

                        <!-- ID or passport -->
                        <div class="form-group">
                            <div>
                                <span class="red-star">*</span>
                                <input type="radio" id="idOption" value="id" v-model="form.documentType" checked>
                                <label for="idOption" style="margin-left: 10px; margin-right: 2px">תעודת זהות</label>
                                <input type="radio" id="passportOption" value="passport" v-model="form.documentType">
                                <label for="passportOption" style="margin-right: 2px">דרכון</label>
                            </div>
                        </div>

                        <!-- ID Input -->
                        <div class="form-group" v-if="form.documentType === 'id'">
                            <span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">badge</span></span> -->
                            <label for="israel_id">תעודת זהות (כולל ספרת ביקורת)</label>
                            <input aria-required="true" type="text" id="israel_id" v-model="form.israel_id"
                                class="form-control" :class="{ 'border-red': fieldError.includes('israel_id') }"
                                @blur="checkForm && validateForm('israel_id')">
                            <span v-if="fieldError.includes('israel_id')" class="error-message">יש להזין מספר תעודת זהות
                                תקין</span>
                        </div>

                        <!-- Passport Input -->
                        <div class="form-group" v-if="form.documentType === 'passport'">
                            <span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">travel_explore</span></span> -->
                            <label for="passport_number">מספר דרכון</label>
                            <input aria-required="true" type="text" id="passport_number" v-model="form.passport_number"
                                class="form-control" :class="{ 'border-red': fieldError.includes('passport_number') }"
                                @blur="checkForm && validateForm('passport_number')">
                            <span v-if="fieldError.includes('passport_number')" class="error-message">יש להזין מספר
                                דרכון תקין</span>
                        </div>


                        <div style="display: flex; align-items: flex-start; gap: 20px;">
                            <!-- phone  -->
                            <div class="form-group" style="flex: 2;">
                                <span class="red-star">*</span>
                                <!-- <span class="form-icon"><span class="material-icons">call</span></span> -->
                                <label for="phone">טלפון נייד</label>
                                <input aria-required="true" type="text" id="phone" v-model="form.phone"
                                    class="form-control" :class="{ 'border-red': fieldError.includes('phone') }"
                                    @blur="checkForm && validateForm()">
                                <span v-if="fieldError.includes('phone')" class="error-message">יש להזין מספר טלפון
                                    תקין</span>
                                <span v-if="fieldError.includes('dup_phone')" class="error-message">מספר הטלפון כבר קיים
                                    במערכת</span>
                            </div>
                        </div>
                        <!-- https://beauty-pharm.web.app/ptPDX7egUlMvhSYhLN2b/iQT1ZRHadZUmLCGtpGfb -->
                        <!-- birthdate -->
                        <div id="select-date" class="form-group"><span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">event</span></span> -->
                            <label for="select-date">תאריך לידה</label>
                            <input aria-required="true" type="date" id="date" v-model="form.birthDate"
                                class="form-control"
                                :class="{ 'border-red': fieldError.includes('birthdate') || fieldError.includes('underage') }"
                                @blur="checkForm && validateForm()">
                            <span v-if="fieldError.includes('birthdate')" class="error-message">יש להזין תאריך
                                לידה</span>
                            <span v-else-if="fieldError.includes('underage')" class="error-message">עליך להיות מעל גיל
                                18!</span>
                        </div>

                        <div>
                            <!-- city -->
                            <div id="city-selection" class="form-group">
                                <span class="red-star">*</span>
                                <label for="city-selection">עיר / ישוב</label>
                                <el-select v-model="form.selectedCity" placeholder="בחר עיר"
                                    style="width: 100%; height: 45px;"
                                    :class="{ 'border-red-city': fieldError.includes('city') }" filterable
                                    @change="checkForm && validateForm()">
                                    <el-option v-for="city in cities" :key="city['_id']" :label="city[city_name_key]"
                                        :value="city[city_name_key]">
                                        {{ city[city_name_key] }}
                                    </el-option>
                                </el-select>
                                <span v-if="fieldError.includes('city')" class="error-message">יש למלא עיר</span>
                            </div>
                        </div>

                        <!-- email -->
                        <div class="form-group">
                            <!-- <span class="form-icon"><span class="material-icons">alternate_email</span></span> -->
                            <label for="email">דואר אלקטרוני</label>
                            <input type="email" id="email" v-model="form.email" class="form-control">
                        </div>
                        <div class="form-group">
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <!-- gender -->
                                <span class="red-star">*</span>
                                <label for="gender" style="margin-right: 10px;">מין</label>
                                <div style="margin-right: 10px;">
                                    <input type="radio" id="male" name="gender" value="זכר" v-model="form.gender"
                                        class="gender-radio" @click="checkForm && handleGenderSelection('זכר')">
                                    <label for="male" class="gender-label"
                                        :class="{ 'border-red': fieldError.includes('gender') }"
                                        style="border-radius: 20px;">
                                        <span class="form-icon"><span class="material-icons">man</span></span>
                                        זכר
                                    </label>
                                </div>
                                <div style="margin-right: 10px;">
                                    <input type="radio" id="female" name="gender" value="נקבה" v-model="form.gender"
                                        class="gender-radio" @click="checkForm && handleGenderSelection('נקבה')">
                                    <label for="female" class="gender-label"
                                        :class="{ 'border-red': fieldError.includes('gender') }"
                                        style="border-radius: 20px;">
                                        <span class="form-icon"><span class="material-icons">woman</span></span>
                                        נקבה
                                    </label>
                                </div>
                            </div>
                            <span v-if="fieldError.includes('gender')" class="error-message"
                                style="margin-right: 5px;">יש
                                למלא
                                מין</span>

                        </div>

                        <div id="select-lang" class="form-group">
                            <span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">language</span></span> -->
                            <label for="lang">בחר שפה מועדפת לקבלת דיוור</label>
                            <select id="lang" v-model="form.lang" class="form-control"
                                :class="{ 'border-red': fieldError.includes('lang') }"
                                @change="checkForm && handleLangSelection(form.lang)" style="height: 45px;">
                                <option value="עברית">עברית</option>
                                <option value="רוסית">רוסית</option>
                            </select>
                            <span v-if="fieldError.includes('lang')" class="error-message">יש לבחור שפה</span>
                        </div>

                        <div id="select-branch" class="form-group">
                            <span class="red-star">*</span>
                            <!-- <span class="form-icon"><span class="material-icons">home</span></span> -->
                            <label for="branch">מעוניין.ת לקבל עדכונים ומבצעים על סניף</label>
                            <select id="branch" v-model="form.branch" class="form-control"
                                :class="{ 'border-red': fieldError.includes('branch') }"
                                @change="checkForm && handleBranchSelection(form.branch)" style="height: 45px;">
                                <option value="" disabled selected>בחר סניף</option>
                                <option v-for="branch in branches" :key="branch['branche_num']"
                                    :value="branch['branche_num']">
                                    {{ branch['branche_name'] }}
                                </option>
                            </select>
                            <span v-if="fieldError.includes('branch')" class="error-message">יש לבחור סניף</span>
                        </div>


                        <div class="form-group" style="margin-top: 1rem; margin-bottom: 0;" v-if="!notRobot">
                            <span class="red-star">*</span>
                            <button @click.prevent="invokeRecaptcha"
                                :class="{ 'border-red': fieldError.includes('robot') }"
                                style="padding: 5px; cursor: pointer; border-radius: 20px; background-color: white; height: 45px;">
                                <span v-loading="recap_loading">{{ robotMessage }}</span>
                            </button>
                        </div>
                        <span v-if="fieldError.includes('robot')" class="error-message" style="margin-right: 5px;">יש
                            ללחוץ
                            אני לא רובוט.</span>
                        <span v-if="fieldError.includes('robotTestFailed')" class="error-message"
                            style="margin-right: 5px;">בדיקת אינני רובוט נכשלה!</span>

                        <div class="form-group" style="margin-top: 1rem;">
                            <div class="custom-control custom-checkbox">
                                <div style="display: flex; flex-direction: row;">
                                    <input type="checkbox" id="contract" class="custom-control-input"
                                        v-model="acceptContract" style="margin-top: 5px;">
                                    <label for="sales"
                                        style="font-size: 1rem; font-weight: lighter; flex: 1; margin-right: 10px;">הנני
                                        מאשר.ת את הצטרפותי למועדון בכפוף <u> <a href="#" class="text-blue" tabindex="0"
                                                @click="navigateToContract">
                                                לתקנון המועדון
                                            </a></u> ואת מדיניות הפרטיות ומסכים.ה
                                        לתנאיהם. ההצטרפות היא ללא עלות.
                                    </label>
                                </div>
                                <span v-if="fieldError.includes('acceptContract')" class="error-message">יש לאשר את
                                    התקנון</span>
                            </div>
                            <div class="custom-control custom-checkbox">
                                <div style="display: flex; flex-direction: row; margin-top: 5px;">
                                    <input type="checkbox" id="sales" class="custom-control-input"
                                        v-model="form.getUpdates" style="margin-top: 3px;">
                                    <label for="sales"
                                        style="font-size: 1rem; font-weight: lighter; flex: 1; margin-right: 10px;">
                                        בהצטרפותי למועדון אני מסכים.ה לקבל מידע פרסומי בכל מדיה פרסומית. בכל שלב תוכל.י
                                        להסיר את עצמך.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <button type="submit" class="btn btn-primary"
                                style="background-color: #FEF200; border-radius: 50px; width: 200px; height: 50px; font-size: 1.2rem; border: none; cursor: pointer; border: 1px solid #000; font-weight: 500;">
                                שלח.י חברות
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import axios from 'axios';

import Swal from 'sweetalert2'
import { isValidIsraeliID, isValidIsraeliPhoneNumber, validateHebrewName, validatePassport } from './scripts';
import router from '../../../router';

import { projectFunctions, timestamp } from '../../../firebase/config';
import { get_branches_full, get_full_branches_from_db } from '../../../Methods/rosman_shivuk_funcs';

const api_url = "https://data.gov.il/api/3/action/datastore_search";
const cities_resource_id = "5c78e9fa-c2e2-4771-93ff-7f400a12f7ba";
const city_name_key = "שם_ישוב";
const fieldError = ref([])
const checkForm = ref(false)
const notRobot = ref(false)
const robotTestFailed = ref(false);
const reCaptchaLoaded = ref(false);
const robotMessage = ref('אני לא רובוט')
const branches = ref([])
const acceptContract = ref(false)

const is_loading = ref(false)
const recap_loading = ref(false)

const navigateToContract = (event) => {
    event.preventDefault();
    const routeData = router.resolve({ name: 'Contract' });
    window.open(routeData.href, '_blank');
};

function loadReCaptcha() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LeQpIIqAAAAAP9m-xUDLpPN88-wnOD3w6AHj31h';
    script.onload = () => {
        console.log('reCAPTCHA loaded');
        reCaptchaLoaded.value = true;
    };
    script.onerror = () => {
        console.error('Failed to load reCAPTCHA');
    };
    document.head.appendChild(script);
}

function handleGenderSelection(gender) {
    form.gender = gender;
    validateForm();
}

function handleLangSelection(lang) {
    form.lang = lang;
    validateForm();
}

function handleBranchSelection(branch) {
    form.branch = branch;
    console.log('branch', branch);
    validateForm();
}

function invokeRecaptcha() {
    robotMessage.value = 'בודק';
    if (typeof grecaptcha === 'undefined') {
        console.log('reCAPTCHA library is not loaded yet.');
        setTimeout(invokeRecaptcha, 500);
    } else {
        grecaptcha.ready(() => {
            console.log('testing recap');
            grecaptcha.execute('6LeQpIIqAAAAAP9m-xUDLpPN88-wnOD3w6AHj31h', { action: 'submit' }).then(async (token) => {
                try {
                    recap_loading.value = true
                    const result = await projectFunctions.httpsCallable("verifyTest", { timeout: 60000 })({ token });

                    if (result.data.success) {
                        notRobot.value = true;
                        if (checkForm) { validateForm(); }
                    } else {
                        console.error('Verification failed', result.data);
                        robotTestFailed.value = true;
                        robotMessage.value = 'אני לא רובוט';
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: "ארעה שגיאה בבדיקת אני לא רובוט !",
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                } catch (error) {
                    console.error('Verification error:', error);
                    robotTestFailed.value = true;
                    robotMessage.value = 'אני לא רובוט';
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "ארעה שגיאה בבדיקת אני לא רובוט !",
                        showConfirmButton: false,
                        timer: 3000
                    });
                } finally {
                    recap_loading.value = false
                }
            });
        });
    }
}


const form = reactive({
    firstName: '',
    lastName: '',
    documentType: 'id',
    israel_id: '',
    passport_number: '',
    email: '',
    phone: '',
    birthDate: '',
    selectedCity: '',
    gender: '',
    lang: 'עברית',
    branch: '',
    getUpdates: false
});

watch(
    () => form.documentType,
    (newValue) => {
        if (newValue === 'id') {
            form.passport_number = '';
        } else if (newValue === 'passport') {
            form.israel_id = '';
        }
    }
);

const cities = ref([]);

const checkAge = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    const date18YearsAgo = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate()
    );

    return birthDate < date18YearsAgo;
}

const isDuplicatePhoneNumber = async (phone) => {
    const res = await projectFunctions.httpsCallable('checkDuplicatePhone', { timeout: 60000 })({ phone });
    return res.data
}

const validateForm = () => {
    fieldError.value = []
    if (!validateHebrewName(form.firstName)) { fieldError.value.push('firstName') }
    if (!validateHebrewName(form.lastName)) { fieldError.value.push('lastName') }
    if (!form.passport_number && !isValidIsraeliID(form.israel_id)) { fieldError.value.push('israel_id') }
    if (!form.israel_id && !validatePassport(form.passport_number)) { fieldError.value.push('passport_number') }
    if (!isValidIsraeliPhoneNumber(form.phone)) { fieldError.value.push('phone') }
    if (!form.birthDate) { fieldError.value.push('birthdate') }
    if (!checkAge(form.birthDate)) { fieldError.value.push('underage') }
    if (!form.selectedCity) { fieldError.value.push('city') }
    if (!form.gender) { fieldError.value.push('gender') }
    if (!form.lang) { fieldError.value.push('lang') }
    if (!form.branch) { fieldError.value.push('branch') }
    if (!notRobot.value) { fieldError.value.push('robot') }
    if (robotTestFailed.value) { fieldError.value.push('robotTestFailed') }
    if (!acceptContract.value) { fieldError.value.push('acceptContract') }
    if (fieldError.value.length) { return false }
    return true
}

const submitForm = async () => {
    console.log('form submitted ', form);
    checkForm.value = true;
    if (form.phone) {
        is_loading.value = true;
        if (await isDuplicatePhoneNumber(form.phone)) {
            fieldError.value.push('dup_phone')
            is_loading.value = false;
            return
        }
        is_loading.value = false;
    }

    const validation = validateForm();
    if (!validation) {
        console.log('invalid', fieldError.value);
        return;
    }

    try {
        is_loading.value = true;

        form.createdAt = timestamp();
        const comax = await projectFunctions.httpsCallable('contactComax', { timeout: 540000 })(form);

        if (!comax.data.Success) {
            if (comax.data.Data.Result == 'IDCard Exists') {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "מספר ת.ז. כבר קיים במערכת.\n לא ניתן להצטרף מספר פעמים עם אותו מספר ת.ז.!",
                    showConfirmButton: false,
                    timer: 6000
                });
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "ארעה שגיאה בקליטת הטופס במערכת !",
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            is_loading.value = false;
            return;
        }

        is_loading.value = false;
        Swal.fire({
            position: "center",
            icon: "success",
            title: "פרטיך התקבלו בהצלחה! תודה שהצטרפת למועדון רוסמן מרקט!",
            showConfirmButton: false,
            timer: 3000
        });
    } catch (error) {
        is_loading.value = false;
        console.log('failed to add user data to db', error);
        Swal.fire({
            position: "center",
            icon: "error",
            title: "ארעה שגיאה בשליחת הטופס!",
            showConfirmButton: false,
            timer: 3000
        });
    } finally {
        is_loading.value = false;
        checkForm.value = false;
    }
};


const getData = async (resource_id, query = "", limit = "100") => {
    try {
        const response = await axios.get(api_url, {
            params: { resource_id, q: query, limit },
            responseType: "json"
        });
        return response.data.result.records;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

const fetchCities = async () => {
    if (cities.value.length === 0) {
        cities.value = await getData(cities_resource_id, "", 32000);
        cities.value.sort((a, b) => {
            if (a[city_name_key] < b[city_name_key]) {
                return -1;
            }
            if (a[city_name_key] > b[city_name_key]) {
                return 1;
            }
            return 0;
        });
    }
};


onMounted(async () => {
    is_loading.value = true

    await fetchCities();
    branches.value = await get_branches_full()
    console.log('branches', branches.value);

    loadReCaptcha();
    is_loading.value = false
});
</script>


<style scoped>
.home-page {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    z-index: 10;
    background-color: #F5F4EF;
}

.title1 {
    text-align: center;
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 10px;
}

.title2 {
    text-align: center;
    font-size: 18.5px;
    font-weight: 300;
}

.text-blue {
    color: #1579F2;
    text-decoration: none;
    background-color: transparent;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .home-page-container {
        width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .home-page-container {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        max-width: 800px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .home-page-container {
        width: 80%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .home-page-container {
        width: 70%;
    }
}

.custom-control-input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: none;
    /* Remove border as it will be styled via the label pseudo-element */
    appearance: none;
    /* Remove the default checkbox appearance */
    -webkit-appearance: none;
    /* For Safari */
    background-color: transparent;
    /* Set transparent background initially */
    outline: none;
    /* Remove focus outline */
    position: relative;
    /* Position for pseudo-elements */
    border-radius: 50%;
    /* Make it circular */
    border: 2px solid #000;
    /* Add a border to mimic a radio button */
    display: inline-block;
    /* Keep it inline */
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.custom-control-input:checked {
    background-color: #000;
    /* Change background color when checked */
    border-color: #000;
    /* Keep the border consistent */
}

.custom-control-input:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    /* Add focus effect */
}

.custom-control-label::before {
    content: "";
    /* Add an empty content for styling */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    /* Make it circular */
    border: 2px solid #000;
    background: transparent;
}

.custom-control-label {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding-left: 40px;
    /* Space for the "radio" circle */
    position: relative;
    /* Required for pseudo-elements */
}

.custom-control-input:checked+.custom-control-label::before {
    background-color: #000;
    /* Fill the circle when checked */
    border-color: #000;
    /* Keep the border consistent */
}


.card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: none;
    background: transparent;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;

}

.card-img,
.card-img-top,
.card-img-big {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}

.card-img,
.card-img-bottom,
.card-img-top {
    flex-shrink: 0;
    width: 50%;
}

.card-img-big {
    flex-shrink: 0;
    width: 100%;
}

img,
svg {
    vertical-align: middle;
}

img {
    border-style: none;
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    margin-bottom: 1.5rem;
    direction: rtl;
}

form {
    display: block;
    margin-top: 0em;
    unicode-bidi: isolate;
}

.form-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    height: 20px;
    vertical-align: middle;
    width: 20px;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 5px;
}

.form-control {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0;
    color: #495057;
    display: block;
    font-size: .9rem;
    font-weight: 400;
    height: calc(1.6em + .75rem + 2px);
    line-height: 1.6;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

input[type="text" i] {
    padding-block: 1px;
    padding-inline: 5px;
}

input,
select {
    border-radius: 20px !important;
}

.gender-radio {
    display: none;
    /* Hide the default radio button */
}

.gender-label {
    display: inline-block;
    background-color: white;
    /* Light grey background, change as needed */
    padding: 5px 20px;
    border: 1px solid #ccc;
    /* Grey border */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

/* Style for when the radio button is checked */
.gender-radio:checked+.gender-label {
    border-color: #000;
    /* Darker blue border for checked state */
}

label {
    margin-bottom: .1rem;
    display: inline-block;
    font-size: large;
    font-weight: 400;

}

button,
input {
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

.form-row {
    margin-left: -5px;
    margin-right: -5px;
}

.form-group {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 1rem;
}

.red-star {
    color: #bb2429;
    margin-left: 2px;
}

.error-message {
    color: #bb2429;
    font-size: 0.8rem;
}

.border-red {
    color: #bb2429;
    border: 1px solid #bb2429;
}

::v-deep(.el-input__inner) {
    border: 1px solid #000;
    border-radius: 20px;
    height: 45px;
}

::v-deep(.border-red-city .el-input__inner) {
    color: #bb2429;
    border: 1px solid #bb2429;
    border-radius: 20px;
    height: 45px;
}

input {
    height: 45px !important;
}

input[type="radio"] {
    height: auto !important;
}

input[type="checkbox"] {
    height: 16px !important;
}

/* General */
.mobile-only {
    display: none;
}

.desktop-only {
    display: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 1350px) {
    .mobile-only {
        display: flex;
        flex-direction: column;
    }

    .desktop-only {
        display: none !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1351px) {
    .desktop-only {
        display: block;
    }
}
</style>